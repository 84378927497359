import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from '../../../features/login/loginSlice';
import { RootState } from '../../../app/store';
// import MoonLoader from 'react-spinners/MoonLoader';
import Spinner from '../../ui/spinner/spinner';

const PrivateRoute = ({ element: Component, ...rest }: any) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => !!state.login.user);
  const isLoading = useSelector((state: RootState) => state.login.isLoading);
  const isAuthChecked = useSelector(
    (state: RootState) => state.login.isAuthChecked
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      // @ts-ignore
      dispatch(checkAuth());
    } else {
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    }
  }, [dispatch]);

  if (isLoading || !isAuthChecked) {
    console.log('Loading...');

    return (
      <div>
        {/* <MoonLoader color={'#000'} loading={true} /> */}
        <Spinner />
      </div>
    );
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to='/login' />;
};

export default PrivateRoute;
