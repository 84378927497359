// import React from 'react';

// interface TimeZoneSelectComponentProps {
//   options: string[];
//   value: string;
//   onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
//   className?: string;
// }

// const TimeZoneSelectComponent: React.FC<TimeZoneSelectComponentProps> = ({
//   options,
//   value,
//   onChange,
//   className,
// }) => {
//   return (
//     <select className={className} value={value} onChange={onChange}>
//       {options.map((option, index) => (
//         <option key={index} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// };

// export default TimeZoneSelectComponent;

import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import './select-component.scss';
import useOnClickOutside from '../../../hooks';

const TimeZoneSelectComponent = ({
  className,
  options,
  value,
  onChange,
}: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Преобразуем опции из строк в объекты
  const formattedOptions = options.map((option: any) => ({
    value: option,
    label: option,
  }));

  const defaultValue = formattedOptions.find(
    (option: any) => option.value === value
  );
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [value, options]);

  const handleChange = (option: any) => {
    setSelectedOption(option);
    onChange({ target: { value: option.value } });
  };

  useOnClickOutside(ref, () => setMenuIsOpen(false));

  return (
    <div className='full-width' ref={ref}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={formattedOptions}
        className={`${className} time-select-wrapper`}
        classNamePrefix='custom-react-select'
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </div>
  );
};

export default TimeZoneSelectComponent;
