import React from 'react';
import SuccessRegistration from '../../../assets/images/success-registration.png';
import './success-registration-code.scss';
import MainLayout from '../../layouts/mainLayout';
import { useNavigate } from 'react-router-dom';

const SuccessRegistrationCode = () => {
  const navigate = useNavigate();

  setTimeout(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'registration',
      formName: 'regConfirm',
    });

    navigate('/advertising-systems-office');
  }, 4000);

  return (
    <MainLayout>
      <div className='recovery-container recovery-container--padding-top-none'>
        <div className='recovery-main success-registration-box'>
          <img src={SuccessRegistration} alt='SuccessRegistration' />
          <p className='recovery-main-body'>
            Аккаунт успешно подтверждён, сейчас вы <br /> будете перенаправлены
            на страницу входа
          </p>
          <div className='recovery-btn-box'></div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SuccessRegistrationCode;
