import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useUTMCookies = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');
    const utmContent = params.get('utm_content');
    const utmTerm = params.get('utm_term');
    const userAgent = navigator.userAgent; 

    if (utmSource) {
      document.cookie = `utm_source=${utmSource}; path=/`;
    }
    if (utmMedium) {
      document.cookie = `utm_medium=${utmMedium}; path=/`;
    }
    if (utmCampaign) {
      document.cookie = `utm_campaign=${utmCampaign}; path=/`;
    }
    if (utmContent) {
      document.cookie = `utm_content=${utmContent}; path=/`;
    }
    if (utmTerm) {
      document.cookie = `utm_term=${utmTerm}; path=/`;
    }

    document.cookie = `userAgent=${encodeURIComponent(userAgent)}; path=/`;
  }, [location]);
};

export default useUTMCookies;
