import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './registration.scss';
import InputLabel from '../../../input/inputLabel/Input-label';
import EyeIcon from '../../../../assets/icons/eye-icon';
import SelectComponent from '../../select/select-component';
// @ts-ignore
import InputCheckbox from '../../../input/InputCheckbox/input-checkbox';
import ButtonComponent from '../../button/button';
import { registerUser } from '../../../../features/register/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import ErrorComponent from '../../error/error-component';
import Spinner from '../../spinner/spinner';
import Cookies from 'js-cookie';

const RegistrationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [messengerType, setMessengerType] = useState('skype');
  const [messenger, setMessenger] = useState('');
  const [formError, setFormError] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  // errors
  const [emailError, setEmailError] = useState(false);
  const [noUniqueemailError, setNoUniqueemailError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const isLoading = useSelector((state: any) => state.auth.isLoading);

  const formatPhoneNumber = (phone: string) => {
    return phone.replace(/\D/g, '');
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formattedPhone = formatPhoneNumber(phone);
    const role = 'user';

    const utm_source = Cookies.get('utm_source');
    const utm_medium = Cookies.get('utm_medium');
    const utm_campaign = Cookies.get('utm_campaign');
    const utm_content = Cookies.get('utm_content');
    const utm_term = Cookies.get('utm_term');
    const userAgent = Cookies.get('userAgent');

    const userData = {
      email,
      password,
      name,
      phone: formattedPhone,
      messenger,
      status: 'inactive',
      messengerType,
      role,
      source: utm_source,
      medium: utm_medium,
      campaign: utm_campaign,
      content: utm_content,
      term: utm_term,
      userAgent: userAgent,
    };

    try {
      setNoUniqueemailError(false);

      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }

      let result: any;

      if (
        email &&
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) &&
        password === repeatPassword
      ) {
        // @ts-ignore
        result = await dispatch(registerUser(userData));
      }

      let resultPayload: any;

      if (typeof result?.payload == 'string') {
        resultPayload = result?.payload;
      }

      const phoneErrorExists = result?.payload?.errors?.some(
        (err: any) => err.msg === 'Неправильный номер мобильного телефона'
      );

      const regex = /<pre>(.*?)<\/pre>/;
      const matches = resultPayload?.match(regex);

      if (matches && matches[1]) {
        const errorMessage = matches[1].split('<br>')[0];
        if (errorMessage.includes('Пользователь с почтовым адресом')) {
          setNoUniqueemailError(true);
        } else {
          setNoUniqueemailError(false);
        }
      }
      if (!result?.error && result !== undefined) {
        navigate('/approve-registration-code');
      } else {
        setError(true);
      }

      if (password !== repeatPassword) {
        setRepeatPasswordError(true);
      } else {
        setRepeatPasswordError(false);
      }

      if (phoneErrorExists) {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    } catch (rejectedValueOrSerializedError) {}
  };

  return (
    <>
      <div className='register-form'>
        <form onSubmit={handleSubmit}>
          <div className='register-container'>
            {isLoading && (
              <div className={'register-container__absolute-layer'}>
                <Spinner />
              </div>
            )}
            <h1>Регистрация</h1>
            <InputLabel
              className='register-input'
              labelClass='register-label'
              label='Имя'
              type='text'
              setValue={setName}
              value={name}
              required
              setFormError={setFormError}
              formError={formError}
            />
            <InputLabel
              className='register-input'
              labelClass='register-label'
              label='Email'
              type='text'
              setValue={setEmail}
              value={email}
              required
              setFormError={setFormError}
              formError={formError}
            />
            {emailError && (
              <ErrorComponent
                error='email должен быть действительным'
                errorClassName=''
              />
            )}
            {noUniqueemailError && (
              <ErrorComponent
                error='Пользователь с таким почтовым адресом уже существует'
                errorClassName=''
              />
            )}
            <InputLabel
              className='register-input'
              labelClass='register-label'
              label='Пароль'
              type='password'
              icon={<EyeIcon />}
              setValue={setPassword}
              value={password}
              iconClass='eye-class'
              required
              setFormError={setFormError}
              formError={formError}
            />
            <InputLabel
              className='register-input'
              labelClass='register-label'
              label='Подтвердить пароль'
              type='password'
              icon={<EyeIcon />}
              iconClass='eye-class'
              setValue={setRepeatPassword}
              value={repeatPassword}
              required
              setFormError={setFormError}
              formError={formError}
            />
            {repeatPasswordError && (
              <ErrorComponent error='пароль не совпадает' errorClassName='' />
            )}
            <div>
              <div className='label-container'>
                <label className='register-label'>Телефон</label>
                {true && <p className='label-required'>*</p>}
              </div>
              <div className='phone-box'>
                <InputLabel
                  className='register-input phone-input'
                  labelClass='register-label'
                  type='tel'
                  setValue={setPhone}
                  value={phone}
                  requireLabel={false}
                  required
                  setFormError={setFormError}
                  formError={formError}
                  placeholder={'+7 (999) 890 90 80'}
                />
              </div>
              {phoneError && (
                <ErrorComponent
                  error='Неправильный формат номера телефона'
                  errorClassName=''
                />
              )}
            </div>
            <div>
              <div className='label-container'>
                <label className='register-label'>Мессенджер</label>
                {true && <p className='label-required'>*</p>}
              </div>
              <div className='select-box'>
                <SelectComponent
                  className='register-select'
                  setMessengerType={setMessengerType}
                />
                <InputLabel
                  className='register-input registration-input'
                  labelClass='register-label'
                  type='text'
                  requireLabel={false}
                  setValue={setMessenger}
                  value={messenger}
                  required
                  setFormError={setFormError}
                  formError={formError}
                />
              </div>
            </div>
            <div>
              <label className='custom-checkbox'>
                <InputCheckbox
                  className='register-checkbox'
                  id='checkbox'
                  onChange={(e: any) => setIsCheckboxChecked(e.target.checked)}
                />
                <span className='checkmark'></span>
                Соглашаюсь с условиями{' '}
                <Link to='/privacy-policy' target='_blank'>
                  обработки персональных данных
                </Link>{' '}
                и{' '}
                <Link to='/user-agreement' target='_blank'>
                  оферты
                </Link>
              </label>
            </div>
            <div className='registration-container'>
              <ButtonComponent
                btnText='Создать аккаунт'
                className='register-btn'
                disabled={!isCheckboxChecked}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegistrationForm;
