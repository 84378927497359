import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './advertising-systems-office.scss';
import Facebook from '../../../assets/images/facebook.png';
import ButtonComponent from '../../ui/button/button';
import ArrowUp from '../../../assets/icons/arrow-up';
import ArrowDown from '../../../assets/icons/arrow-down';
// import ArrowLeft from '../../../assets/icons/arrow-left';
import MainLayout from '../../layouts/mainLayout';

const AdvertisingSystemsOffice = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showDetails, setShowDetails] = useState(true);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  // const goBack = () => {
  //   navigate(-1);
  // };

  const handleNavigateAddOffice = () => {
    navigate('/add-advertising-systems-office');
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      if (location.pathname === '/') {
        navigate('/');
      }
    };

    window.addEventListener('popstate', handlePopState);
  }, [location, navigate]);

  return (
    <MainLayout>
      {/* <div className='advertising-systems-page__back-button' onClick={goBack}>
        <ArrowLeft />
        <div>назад</div>
      </div> */}
      <div className='networks-box recovery-container recovery-container--justify-start'>
        <div className='networks-item'>
          <div className='networks-item--left'>
            <div className='networks-left-side-box'>
              <img src={Facebook} alt='Facebook' />
              <div className='networks-title'>Fb Accounts</div>
              <div className='networks-status'>
                Подключенных кабинетов <span> 0 </span>{' '}
              </div>
              <ButtonComponent
                btnText='+ Добавить кабинет'
                className={`advertising-btn advertising-btn--mobile-hidden`}
                btnOnClick={handleNavigateAddOffice}
              />
            </div>
          </div>
          <div className='networks-item__right-side'>
            <div
              className='networks-item__hidden-function'
              onClick={toggleDetails}
            >
              <div>{showDetails ? 'Скрыть' : 'Показать'} подробности</div>
              {showDetails ? <ArrowUp /> : <ArrowDown />}
            </div>

            {showDetails && (
              <div className='networks-right-side-box'>
                <p className='networks-right-side-text'>
                  Валюта:{' '}
                  <span className='networks-right-side-span'>  USD </span>
                </p>
                <p className='networks-right-side-text'>
                  Минимальный платеж:{' '}
                  <span className='networks-right-side-span'>  $100 </span>
                </p>
                <p className='networks-right-side-text'>
                  ВАЖНО:{' '}
                  <span className='networks-right-side-span'>
                    {' '}
                    Для доступов к рекламным кабинетам необходимо указать <br />{' '}
                    email вне RU зоны. На email в зонах .ru, .su, .рф доступы
                    выданы не <br /> будут.{' '}
                  </span>
                </p>
              </div>
            )}

            <div className='networks-right-side-btns-box'>
              <ButtonComponent
                btnText='Описание'
                className='networks-right-side-btn'
              />
              <ButtonComponent
                btnText='Правила'
                className='networks-right-side-btn'
              />
              <ButtonComponent
                btnText='Как подключить'
                className='networks-right-side-btn'
              />
            </div>
            <div className='networks-item--center'>
              <ButtonComponent
                btnText='+ Добавить кабинет'
                className={`advertising-btn advertising-btn--desktop-hidden`}
                btnOnClick={handleNavigateAddOffice}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AdvertisingSystemsOffice;
