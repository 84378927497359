import React from 'react'

const ErrorComponent = ({error, errorClassName}: any) => {
    return (
        <div className={`approve-recovery-main__wrong-code ${errorClassName? errorClassName : ""}`}>
            {error}
        </div>
    )
}

export default ErrorComponent