import './footer-component.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const FooterComponent = ({
  className,
  rightSideClassname,
  leftsideText,
}: any) => {
  const location = useLocation();
  const pathname = location?.pathname;
  return (
    <div className={className}>
      <p className={leftsideText}>© 2024 Ad.Kitchen </p>
      <div className={rightSideClassname}>
        <Link to='/user-agreement' className='user-agreement-link'>
          Пользовательское соглашение
        </Link>
        <Link to='/privacy-policy' className='user-politics'>
          Политика безопасности
        </Link>

        {pathname === '/landing' && (
          <Link to='#' className='user-oferta'>
            Оферта
          </Link>
        )}
      </div>
    </div>
  );
};

export default FooterComponent;
