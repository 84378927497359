import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from '../../ui/button/button';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import UserIcon from '../../../assets/icons/user-icon';
import MainLayout from '../../layouts/mainLayout';

const RecoveryPasswordDone = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  const handleDone = () => {
    navigate('/login');
  }

  return (
    <MainLayout>
      <div className='recovery-container '>
        <div className='recovery-main check-password recovery-password-done'>
          <div>
            <p className='recovery-title'>Пароль изменен</p>
            <p className='recovery-main-body'>
              Ваш пароль успешно изменен. Теперь вы можете использовать новый
              пароль для входа.{' '}
            </p>
          </div>
          <ButtonComponent btnText='Готово' className='recovery-form-btn'  btnOnClick={handleDone} />
        </div>
        <div className='registration__signin-section'>
          <p>Нет аккаунта?</p>
          <ButtonWithIcon
            btnText={'Зарегистрироваться'}
            widtIcon={true}
            icon={<UserIcon />}
            btnIconClassName='register-header-btn-icon'
            className='registration-header-btn-icon'
            btnOnClick={handleClick}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default RecoveryPasswordDone;
