export const countries = [
  { name: 'Russia', code: 'RU', mask: '+7 (999) 999-99-99' },
  { name: 'Ukraine', code: 'UA', mask: '+380 (99) 999-99-99' },
  { name: 'India', code: 'IN', mask: '+\\91 (9999) 999-999' },
  { name: 'Kazakhstan', code: 'KZ', mask: '+7 (600) 999-99-99' },
  { name: 'Uzbekistan', code: 'UZ', mask: '+\\9\\98 (99) 999-99-99' },
  { name: 'United States', code: 'US', mask: '+1 (999) 999-9999' },
  { name: 'Kyrgyzstan', code: 'KG', mask: '+\\9\\96 (999) 999-999' },
  { name: 'Belarus', code: 'BY', mask: '+375 (99) 999-99-99' },
  { name: 'Poland', code: 'PL', mask: '+48 999-999-999' },
  { name: 'China', code: 'CN', mask: '+86 (999) 9999-9999' },
  { name: 'Georgia', code: 'GE', mask: '+\\9\\95 (999) 999-999' },
  { name: 'Hong Kong', code: 'HK', mask: '+852 9999-9999' },
  { name: 'Armenia', code: 'AM', mask: '+374 (99) 999-999' },
  { name: 'Moldova', code: 'MD', mask: '+373 (99) 999-999' },
  { name: 'Germany', code: 'DE', mask: '+4\\9 (999) 999-9999' },
  { name: 'Turkey', code: 'TR', mask: '+\\90 (999) 999-9999' },
  { name: 'Netherlands', code: 'NL', mask: '+31 (99) 999-9999' },
  { name: 'United Kingdom', code: 'GB', mask: '+44 99-9999-9999' },
  { name: 'Singapore', code: 'SG', mask: '+65 9999-9999' },
  { name: 'Thailand', code: 'TH', mask: '+66 99-999-9999' },
  { name: 'Azerbaijan', code: 'AZ', mask: '+\\9\\94 (99) 999-99-99' },
];