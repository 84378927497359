import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '../../../input/inputLabel/Input-label';
import EyeIcon from '../../../../assets/icons/eye-icon';
import ButtonComponent from '../../button/button';
import ErrorComponent from '../../error/error-component';
import ModalComponent from '../../modal/modal';
import { loginUser } from '../../../../features/login/loginSlice';
import '../registration/registration.scss';
import './login-form.scss';
// import MoonLoader from 'react-spinners/MoonLoader';
import Spinner from '../../spinner/spinner';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state: any) => state.login.isLoading);
  // console.log('🚀 ~ LoginForm ~ isLoading:', isLoading);

  const handleClickRecover = () => {
    navigate('/recovery-password');
  };

  const handleCancelModal = () => {
    setShowLoginModal(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const userData = {
      email,
      password,
    };
    try {
      let result;
      if (email && password) {
        // @ts-ignore
        result = await dispatch(loginUser(userData)).unwrap();
      }

      const user = result.user;
      console.log('user', user);

      if (result !== undefined) {
        if (user.twoFactor === true) {
          setShowLoginModal(true);
        }
        navigate('/advertising-systems-office');
      }
    } catch (rejectedValueOrSerializedError) {
      setError(true);
    }
  };

  return (
    <div className='register-form'>
      {showLoginModal && (
        <>
          <div className='header-absolute-background-color position-fixed'></div>
          <ModalComponent
            twoBtn
            className='add-advetising-modal'
            firstBtnText='Отменить'
            secondBtnText='Пополнить'
            firstBtnClick={handleCancelModal}
            modalBtnClassName='add-advetising-modal-btn'
            modalBtnHeaderClassName='add-advetising-modal-header'
            // btnOnClick={handleOffices}
            modalText='<p className={modalBtnHeaderClassName}>
            Google 2FA
          </p>
          <p>Введите код из приложения</p>'
          />
        </>
      )}
      <form onSubmit={handleSubmit}>
        <div className='register-container'>
          {isLoading && (
            <div className={'register-container__absolute-layer'}>
              <Spinner />
            </div>
          )}
          <h1>Вход</h1>
          <InputLabel
            className='register-input'
            labelClass='register-label'
            label='Email'
            type='text'
            setValue={setEmail}
            value={email}
            onChange={setEmail}
          />
          <InputLabel
            className='register-input'
            labelClass='register-label'
            label='Пароль'
            type='password'
            setValue={setPassword}
            value={password}
            onChange={setPassword}
            icon={<EyeIcon />}
            iconClass='eye-class'
          />
          <p className='login-text-forget' onClick={handleClickRecover}>
            Забыли пароль?
          </p>
          {error && (
            <ErrorComponent
              error='Некорректный email или пароль'
              errorClassName='login-error'
            />
          )}
          <div className='registration-container login-box'>
            <ButtonComponent btnText={'Войти'} className='register-btn' />
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
