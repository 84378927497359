import React, { useRef, useState } from 'react';
import Select from 'react-select';
import Skype from '../../../assets/icons/skype-icon';
import Telegram from '../../../assets/icons/telegram-icon';
import './select-component.scss';
import useOnClickOutside from '../../../hooks';

const SelectComponent = ({
  className,
  timeZoneOptions,
  setMessengerType,
}: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleChange = (option: any) => {
    setSelectedOption(option);
    setMessengerType(option?.value);
  };

  const options = [
    { value: 'Skype', label: <Skype /> },
    { value: 'Telegram', label: <Telegram /> },
  ];

  const timeOptions: any = [{ value: 'GMT+3', label: 'GMT+3' }];

  const defaultValue = timeZoneOptions ? timeOptions[0] : options[0];
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  // Use the hook to close the select when clicking outside
  useOnClickOutside(ref, () => setMenuIsOpen(false));

  return (
    <div className='select-wrapper' ref={ref}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        defaultValue={defaultValue}
        options={timeZoneOptions ? timeOptions : options}
        className={className}
        classNamePrefix='custom-react-select'
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </div>
  );
};

export default SelectComponent;
