import React from 'react'

const ButtonWithIcon = ({ className, btnText, widtIcon = false, icon, btnIconClassName, btnOnClick }: any) => {
  return (
    <>
      <button
        className={className}
        onClick={btnOnClick}
      >
        {widtIcon ?
          <div className={btnIconClassName}>
            {icon}
            {btnText}
          </div> :
          btnText
        }

      </button>
    </>

  )
}

export default ButtonWithIcon