import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import './App.css';
import RegistrationPage from './components/pages/registrationPage/registration-page';
import LoginPage from './components/pages/loginPage/login-page';
import RecoveryPassword from './components/pages/recovery-password/recovery-password';
import RecoveryPasswordCheck from './components/pages/recovery-password/recovery-password-check';
import RecoveryPasswordForm from './components/pages/recovery-password/recovery-password-form';
import AdvertisingSystemsOffice from './components/pages/advertising-systems-office/advertising-systems-office';
import ApproveRegistrationCode from './components/pages/approve-registration-code/approve-registration-code';
import SuccessRegistrationCode from './components/pages/success-registration-code/success-registration-code';
import AddAdvertisingSystemsOffice from './components/pages/add-advertising-systems-office/add-advertising-systems-office';
import RecoveryPasswordDone from './components/pages/recovery-password/recovery-password-done';
import UserAgreement from './components/pages/user-agreement/user-agreement';
import OffertLaw from './components/pages/offert-law/offert-law';
import PrivateRoute from './components/services/private-route/PrivateRoute';
import LandingPage from './components/pages/landing/landing';
import useUTMCookies from './hooks/useUTMCookies';

function App() {
  return (
    <Router>
      <UTMCookiesWrapper />
      <Routes>
        <Route path='/register' element={<RegistrationPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/' element={<LandingPage />} />
        <Route path='/recovery-password' element={<RecoveryPassword />} />
        <Route
          path='/recovery-password-check'
          element={<RecoveryPasswordCheck />}
        />
        <Route
          path='/recovery-password-done'
          element={<RecoveryPasswordDone />}
        />
        <Route
          path='/recovery-password-form/:recoverPasswordLink/:encodedEmail/:timestamp'
          element={<RecoveryPasswordForm />}
        />

        <Route
          path='/advertising-systems-office'
          element={<PrivateRoute element={AdvertisingSystemsOffice} />}
        />
        {/* <Route
          path='/advertising-systems-office-home'
          element={<PrivateRoute element={AdvertisingSystemsOfficeHome} />}
        /> */}
        <Route
          path='/add-advertising-systems-office'
          element={<PrivateRoute element={AddAdvertisingSystemsOffice} />}
        />
        <Route
          path='/approve-registration-code'
          element={<ApproveRegistrationCode />}
        />
        <Route
          path='/success-registration-code'
          element={<SuccessRegistrationCode />}
        />
        <Route path='/privacy-policy' element={<UserAgreement />} />
        <Route path='/user-agreement' element={<OffertLaw />} />
      </Routes>
    </Router>
  );
}

const UTMCookiesWrapper = () => {
  useUTMCookies();
  return null;
};

export default App;
