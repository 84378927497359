import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const registerUser = createAsyncThunk(
  'auth/register',
  async (userData, { rejectWithValue }) => {
    try {
      console.log('userData', userData)
      const response = await $api.post('/api/user/registration', userData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const repeatConfirmCode = createAsyncThunk(
  'auth/repeatConfirmCode',
  async (email, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/user/repeat-confirm-code', { email });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(repeatConfirmCode.pending, (state: any) => {
        state.isLoading = true;
      })
      .addCase(repeatConfirmCode.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.repeatConfirmCodeStatus = action.payload;
      })
      .addCase(repeatConfirmCode.rejected, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
})

export default authSlice.reducer;
