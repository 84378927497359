import React from 'react';

const MainLogo = () => {
  return (
    <svg
      width='148'
      height='24'
      viewBox='0 0 148 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 20.51L6.356 0.35H11.298L17.654 20.51H14.182L8.414 2.422H9.17L3.472 20.51H0ZM3.542 16.142V12.992H14.126V16.142H3.542Z'
        fill='black'
      />
      <path
        d='M26.2472 20.93C24.8565 20.93 23.6432 20.58 22.6072 19.88C21.5712 19.18 20.7685 18.228 20.1992 17.024C19.6298 15.82 19.3452 14.462 19.3452 12.95C19.3452 11.4193 19.6298 10.0567 20.1992 8.862C20.7778 7.658 21.5945 6.71067 22.6492 6.02C23.7038 5.32 24.9452 4.97 26.3732 4.97C27.8105 4.97 29.0145 5.32 29.9852 6.02C30.9652 6.71067 31.7072 7.658 32.2112 8.862C32.7152 10.066 32.9672 11.4287 32.9672 12.95C32.9672 14.4527 32.7152 15.8107 32.2112 17.024C31.7072 18.228 30.9558 19.18 29.9572 19.88C28.9585 20.58 27.7218 20.93 26.2472 20.93ZM26.7652 17.906C27.6705 17.906 28.3985 17.7007 28.9492 17.29C29.5092 16.87 29.9152 16.2867 30.1672 15.54C30.4285 14.7933 30.5592 13.93 30.5592 12.95C30.5592 11.9607 30.4285 11.0973 30.1672 10.36C29.9152 9.61333 29.5185 9.03467 28.9772 8.624C28.4358 8.204 27.7358 7.994 26.8772 7.994C25.9718 7.994 25.2252 8.218 24.6372 8.666C24.0492 9.10467 23.6152 9.702 23.3352 10.458C23.0552 11.2047 22.9152 12.0353 22.9152 12.95C22.9152 13.874 23.0505 14.714 23.3212 15.47C23.6012 16.2167 24.0258 16.8093 24.5952 17.248C25.1645 17.6867 25.8878 17.906 26.7652 17.906ZM30.5592 20.51V9.898H30.1392V0.35H33.5412V20.51H30.5592Z'
        fill='black'
      />
      <path
        d='M45.9703 20.51V0.35H49.3443V9.702L57.3943 0.35H61.5383L52.8723 10.234L62.1263 20.51H57.8423L49.3443 11.102V20.51H45.9703Z'
        fill='black'
      />
      <path
        d='M64.3792 3.094V0H67.7532V3.094H64.3792ZM64.3792 20.51V5.39H67.7532V20.51H64.3792Z'
        fill='black'
      />
      <path
        d='M80.8089 20.51C79.8102 20.6967 78.8302 20.776 77.8689 20.748C76.9169 20.7293 76.0629 20.5567 75.3069 20.23C74.5509 19.894 73.9769 19.3667 73.5849 18.648C73.2395 17.9947 73.0575 17.3273 73.0389 16.646C73.0202 15.9647 73.0109 15.1947 73.0109 14.336V1.19H76.3709V14.14C76.3709 14.7467 76.3755 15.2787 76.3849 15.736C76.4035 16.1933 76.5015 16.5667 76.6789 16.856C77.0149 17.416 77.5515 17.7287 78.2889 17.794C79.0262 17.8593 79.8662 17.822 80.8089 17.682V20.51ZM70.2669 8.036V5.39H80.8089V8.036H70.2669Z'
        fill='black'
      />
      <path
        d='M89.8605 20.93C88.3019 20.93 86.9719 20.5847 85.8705 19.894C84.7692 19.194 83.9245 18.242 83.3365 17.038C82.7579 15.834 82.4639 14.4713 82.4545 12.95C82.4639 11.4007 82.7672 10.0287 83.3645 8.834C83.9712 7.63 84.8299 6.68733 85.9405 6.006C87.0512 5.31533 88.3719 4.97 89.9025 4.97C91.6199 4.97 93.0712 5.404 94.2565 6.272C95.4512 7.13067 96.2305 8.30667 96.5945 9.8L93.2345 10.71C92.9732 9.898 92.5392 9.268 91.9325 8.82C91.3259 8.36267 90.6352 8.134 89.8605 8.134C88.9832 8.134 88.2599 8.344 87.6905 8.764C87.1212 9.17467 86.7012 9.744 86.4305 10.472C86.1599 11.2 86.0245 12.026 86.0245 12.95C86.0245 14.3873 86.3465 15.5493 86.9905 16.436C87.6345 17.3227 88.5912 17.766 89.8605 17.766C90.7565 17.766 91.4612 17.5607 91.9745 17.15C92.4972 16.7393 92.8892 16.1467 93.1505 15.372L96.5945 16.142C96.1279 17.682 95.3112 18.8673 94.1445 19.698C92.9779 20.5193 91.5499 20.93 89.8605 20.93Z'
        fill='black'
      />
      <path
        d='M109.858 20.51V13.23C109.858 12.754 109.825 12.2267 109.76 11.648C109.695 11.0693 109.541 10.514 109.298 9.982C109.065 9.44067 108.71 8.99733 108.234 8.652C107.767 8.30667 107.133 8.134 106.33 8.134C105.901 8.134 105.476 8.204 105.056 8.344C104.636 8.484 104.253 8.72667 103.908 9.072C103.572 9.408 103.301 9.87467 103.096 10.472C102.891 11.06 102.788 11.816 102.788 12.74L100.786 11.886C100.786 10.598 101.033 9.43133 101.528 8.386C102.032 7.34067 102.769 6.51 103.74 5.894C104.711 5.26867 105.905 4.956 107.324 4.956C108.444 4.956 109.368 5.14267 110.096 5.516C110.824 5.88933 111.403 6.36533 111.832 6.944C112.261 7.52267 112.579 8.13867 112.784 8.792C112.989 9.44533 113.12 10.066 113.176 10.654C113.241 11.2327 113.274 11.704 113.274 12.068V20.51H109.858ZM99.372 20.51V0.35H102.382V10.962H102.788V20.51H99.372Z'
        fill='black'
      />
      <path
        d='M123.801 20.93C122.271 20.93 120.927 20.5987 119.769 19.936C118.612 19.2733 117.707 18.354 117.053 17.178C116.409 16.002 116.087 14.6487 116.087 13.118C116.087 11.466 116.405 10.0333 117.039 8.82C117.674 7.59733 118.556 6.65 119.685 5.978C120.815 5.306 122.121 4.97 123.605 4.97C125.173 4.97 126.503 5.33867 127.595 6.076C128.697 6.804 129.513 7.83533 130.045 9.17C130.577 10.5047 130.778 12.0773 130.647 13.888H127.301V12.656C127.292 11.0133 127.003 9.814 126.433 9.058C125.864 8.302 124.968 7.924 123.745 7.924C122.364 7.924 121.337 8.35333 120.665 9.212C119.993 10.0613 119.657 11.3073 119.657 12.95C119.657 14.4807 119.993 15.666 120.665 16.506C121.337 17.346 122.317 17.766 123.605 17.766C124.436 17.766 125.15 17.584 125.747 17.22C126.354 16.8467 126.821 16.31 127.147 15.61L130.479 16.618C129.901 17.9807 129.005 19.04 127.791 19.796C126.587 20.552 125.257 20.93 123.801 20.93ZM118.593 13.888V11.34H128.995V13.888H118.593Z'
        fill='black'
      />
      <path
        d='M143.819 20.51V13.23C143.819 12.754 143.786 12.2267 143.721 11.648C143.656 11.0693 143.502 10.514 143.259 9.982C143.026 9.44067 142.671 8.99733 142.195 8.652C141.728 8.30667 141.094 8.134 140.291 8.134C139.862 8.134 139.437 8.204 139.017 8.344C138.597 8.484 138.214 8.72667 137.869 9.072C137.533 9.408 137.262 9.87467 137.057 10.472C136.852 11.06 136.749 11.816 136.749 12.74L134.747 11.886C134.747 10.598 134.994 9.43133 135.489 8.386C135.993 7.34067 136.73 6.51 137.701 5.894C138.672 5.26867 139.866 4.956 141.285 4.956C142.405 4.956 143.329 5.14267 144.057 5.516C144.785 5.88933 145.364 6.36533 145.793 6.944C146.222 7.52267 146.54 8.13867 146.745 8.792C146.95 9.44533 147.081 10.066 147.137 10.654C147.202 11.2327 147.235 11.704 147.235 12.068V20.51H143.819ZM133.333 20.51V5.39H136.343V10.08H136.749V20.51H133.333Z'
        fill='black'
      />
      <path
        d='M38.5681 24V20.4358H35V18.5542H38.5681V15H40.4518V18.5542H44V20.4358H40.4518V24H38.5681Z'
        fill='#FF1053'
      />
    </svg>
  );
};

export default MainLogo;
