import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const addNewOfficeSlice = createAsyncThunk(
  'office/add-office',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/office/add-office', userData);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addOfficeSlice = createSlice({
  name: 'auth',
  initialState: {
    office: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNewOfficeSlice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        addNewOfficeSlice.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.office = action.payload;
        }
      )
      .addCase(
        addNewOfficeSlice.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      );
  },
});

export default addOfficeSlice.reducer;
