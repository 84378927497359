import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const recoveryPassword = createAsyncThunk(
  'recovery-password',
  async (userData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/user/recovery-password', userData);
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const recoveryPasswordSlice = createSlice({
  name: 'recovery-password',
  initialState: {
    recoveryEmail: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(recoveryPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        recoveryPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.recoveryEmail = action.payload;
          state.error = null;
        }
      )
      .addCase(
        recoveryPassword.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload || 'Ошибка авторизации';
        }
      );
  },
});

export default recoveryPasswordSlice.reducer;
