import React from 'react'

const ArrowUp = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 10.75L8.75 7ZM8.75 7L5 10.75Z" fill="#D9D9D9"/>
      <path d="M12.5 10.75L8.75 7L5 10.75" stroke="#8F97A9" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default ArrowUp