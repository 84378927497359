import React, { useState } from 'react';
import ButtonComponent from '../../ui/button/button';
import { useNavigate } from 'react-router-dom';
import InputLabel from '../../input/inputLabel/Input-label';
import SelectConponent from '../../ui/select/select-component';
import './add-advertising-systems-office.scss';
import '../registrationPage/registration-page.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addNewOfficeSlice } from '../../../features/add-office/add-office-slice';
import Facebook from '../../../assets/images/facebook.png';
import ModalComponent from '../../ui/modal/modal';
import MainLayout from '../../layouts/mainLayout';
import ErrorComponent from '../../ui/error/error-component';
import TimeZoneSelectComponent from '../../ui/time-zone-select/time-zone-select';
import EyeIcon from '../../../assets/icons/eye-icon';
import Spinner from '../../ui/spinner/spinner';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const AddAdvertisingSystemsOffice: React.FC = () => {
  const [accountName, setAccountName] = useState('');
  const [landingPage, setLandingPage] = useState('');
  const [FBAPPID, setFBAPPID] = useState('');
  const [BMID, setBMID] = useState('');
  const [FPProfileAccess, setFPProfileAccess] = useState('');
  const [firstTimeRecharge, setFirstTimeRecharge] = useState('');
  const [IDXP, setIDXP] = useState('');
  const [messenger, setMessenger] = useState('');
  const [accountTimeZone, setAccountTimeZone] = useState('GMT+03');
  const [messengerType, setMessengerType] = useState('skype');
  const [showModal, setShowModal] = useState(false);

  // errors
  const [landingError, setLandingError] = useState(false);
  const [appIdError, setAppIdError] = useState(false);
  const [BMIDError, setBMIDError] = useState(false);
  const [firstTimeRechargeError, setFirstTimeRechargeError] = useState(false);
  const [IDXPError, setIDXPError] = useState(false);
  const [accountNameError, setAccountNameError] = useState(false);
  const [messengerError, setMessengerError] = useState(false);
  const isLoading = useSelector((state: any) => state.addOffice?.isLoading);
  console.log('🚀 ~ isLoading:', isLoading);

  const firstTimeRechargeNumber = Number(firstTimeRecharge);
  const IDXPNumber = Number(IDXP);

  const navigate = useNavigate();

  const user = useSelector((state: any) => state.login.user) || false;
  const dispatch = useDispatch();

  const holderEmail = user?.email;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Reset errors
    setAccountNameError(false);
    setBMIDError(false);
    setIDXPError(false);
    setMessengerError(false);

    // Check if all required fields are filled
    let hasError = false;
    if (!accountName) {
      setAccountNameError(true);
      hasError = true;
    }
    if (!BMID) {
      setBMIDError(true);
      hasError = true;
    }
    if (!IDXP) {
      setIDXPError(true);
      hasError = true;
    }
    if (!messenger) {
      setMessengerError(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const userData = {
      accountName,
      landingPage,
      FBAPPID,
      BMID,
      FPProfileAccess,
      firstTimeRecharge,
      IDXP,
      messenger,
      accountTimeZone,
      holderEmail,
      messengerType,
    };
    try {
      if (landingPage && !/^(ftp|http|https):\/\/[^ "]+$/.test(landingPage)) {
        setLandingError(true);
      } else {
        setLandingError(false);
      }

      if (
        FBAPPID &&
        !(typeof FBAPPID === 'string' || typeof FBAPPID === 'number')
      ) {
        setAppIdError(true);
      } else {
        setAppIdError(false);
      }

      if (typeof BMID !== 'string' && typeof BMID !== 'number') {
        setBMIDError(true);
      } else {
        setBMIDError(false);
      }

      if (isNaN(firstTimeRechargeNumber)) {
        setFirstTimeRechargeError(true);
      } else {
        setFirstTimeRechargeError(false);
      }

      if (isNaN(IDXPNumber)) {
        setIDXPError(true);
      } else {
        setIDXPError(false);
      }

      let result;

      // @ts-ignore
      if (
        !landingError &&
        !appIdError &&
        !BMIDError &&
        !firstTimeRechargeError &&
        !IDXPError
      ) {
        // @ts-ignore
        result = await dispatch(addNewOfficeSlice(userData)).unwrap();
      }

      if (result?._id) {
        setShowModal(true);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'formSuccess',
          formName: 'fb',
        });
      }
    } catch (rejectedValueOrSerializedError) {}
  };

  const handleCancelBtn = () => {
    navigate('/advertising-systems-office');
  };

  const timeZoneOptions = [
    'GMT+00',
    'GMT+01',
    'GMT+02',
    'GMT+03',
    'GMT+04',
    'GMT+05',
    'GMT+06',
    'GMT+07',
    'GMT+08',
    'GMT+09',
    'GMT+10',
    'GMT+11',
    'GMT+12',
    'GMT-01',
    'GMT-02',
    'GMT-03',
    'GMT-04',
    'GMT-05',
    'GMT-06',
    'GMT-07',
    'GMT-08',
    'GMT-09',
    'GMT-10',
    'GMT-11',
  ];

  return (
    <MainLayout>
      {showModal && (
        <>
          <div className='header-absolute-background-color position-fixed'></div>
          <ModalComponent
            className='add-advetising-modal'
            modalBtnClassName='add-advetising-modal-btn'
            modalBtnHeaderClassName='add-advetising-modal-header'
          />
        </>
      )}
      <div className='networks-box add-advetising-box'>
        <form onSubmit={handleSubmit}>
          <div className='advertising-systems-page-container'>
            {isLoading && (
              <div
                className={'advertising-systems-page-container__absolute-layer'}
              >
                <Spinner />
              </div>
            )}
            <div className='add-advetising'>
              <div className='add-advetising__fb-ads'>
                <img src={Facebook} alt='Facebook' />
                <div className='networks-title'>Fb Accounts</div>
              </div>
              <div>
                Агентский кабинет Fb. <br /> Минимальное пополнение: 100 USD.
              </div>
            </div>
            <InputLabel
              className={`register-input ${
                accountNameError ? 'error-input' : ''
              }`}
              labelClass='register-label'
              label='Название аккаунта'
              type='text'
              setValue={setAccountName}
              value={accountName}
              requireLabel={true}
            />
            {accountNameError && (
              <ErrorComponent
                error='Название аккаунта обязательно для заполнения'
                errorClassName=''
              />
            )}
            <InputLabel
              className={`register-input`}
              labelClass='register-label'
              label='Landing page'
              type='text'
              setValue={setLandingPage}
              value={landingPage}
              requireLabel={false}
            />
            {landingError && (
              <ErrorComponent
                error='Landing страница должна быть действующим URL-адресом'
                errorClassName=''
              />
            )}
            <InputLabel
              className={`register-input`}
              labelClass='register-label'
              label='FB APP ID'
              type='text'
              setValue={setFBAPPID}
              value={FBAPPID}
              requireLabel={false}
            />
            {appIdError && (
              <ErrorComponent
                error='FB APP ID должен быть строкой или числом'
                errorClassName=''
              />
            )}
            <InputLabel
              className={`register-input ${BMIDError ? 'error-input' : ''}`}
              labelClass='register-label'
              label='BM ID'
              type='text'
              setValue={setBMID}
              value={BMID}
              requireLabel={true}
            />
            {BMIDError && (
              <ErrorComponent
                error='BM ID обязательно для заполнения'
                errorClassName=''
              />
            )}
            <InputLabel
              className={`register-input`}
              labelClass='register-label'
              label='Расшарить права ФП на этот профиль'
              type='text'
              setValue={setFPProfileAccess}
              value={FPProfileAccess}
              requireLabel={false}
              placeholder='Введите email'
            />
            <InputLabel
              className={`register-input`}
              labelClass='register-label'
              label='Cумма для первого пополнения'
              type='text'
              setValue={setFirstTimeRecharge}
              value={firstTimeRecharge}
              requireLabel={false}
              placeholder='Введите сумму'
              rightText='USD'
              iconClass='eye-class'
            />
            {firstTimeRechargeError && (
              <ErrorComponent
                error='Первое пополнение должно быть числом'
                errorClassName=''
              />
            )}
            <InputLabel
              className={`register-input ${IDXPError ? 'error-input' : ''}`}
              labelClass='register-label'
              label='ID вебмастера XP'
              type='text'
              setValue={setIDXP}
              value={IDXP}
              requireLabel={true}
              placeholder='Введите ID'
            />
            {IDXPError && (
              <ErrorComponent
                error='ID вебмастера XP обязательно для заполнения'
                errorClassName=''
              />
            )}
            <div>
              <p className='label-class'>
                Mессенджер <span className='label-class-span'>*</span>
              </p>
              <div className='select-box'>
                <SelectConponent
                  className='register-select'
                  setMessengerType={setMessengerType}
                />
                <InputLabel
                  className={`register-input ${
                    messengerError ? 'error-input' : ''
                  }`}
                  labelClass='register-label'
                  type='text'
                  requireLabel={false}
                  setValue={setMessenger}
                  value={messenger}
                />
              </div>
            </div>
            {messengerError && (
              <ErrorComponent
                error='Mессенджер обязательно для заполнения'
                errorClassName=''
              />
            )}
            <div className='full-width'>
              <p className='label-class'>
                В каком часовом поясе открывать аккаунт
              </p>
              <TimeZoneSelectComponent
                className='time-zone-select'
                options={timeZoneOptions}
                value={accountTimeZone}
                onChange={(e: any) => setAccountTimeZone(e.target.value)}
              />
            </div>

            <div className='add-advetising-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel'
                btnOnClick={handleCancelBtn}
              />
              <ButtonComponent
                btnText='Создать кабинет'
                className='add-advetising-btn-create'
                btnOnClick={handleSubmit}
                // disabled={!email || !password || !repeatPassword}
              />
            </div>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default AddAdvertisingSystemsOffice;
