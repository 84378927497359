import React from 'react';
import '../registrationPage/registration-page.scss';
import FlatIlustration from '../../../assets/images/flat_illustration_37 1.png';
import { useNavigate } from 'react-router-dom';
import UserIcon from '../../../assets/icons/user-icon';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import LoginForm from '../../ui/forms/login/login-form';
import MainLayout from '../../layouts/mainLayout';

const LoginPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/register');
  };

  return (
    <MainLayout>
      <div className='registration--min-height'>
        <div className='registration__column'>
          <div className='registration__main'>
            <div className='registration__left-side'>
              <img src={FlatIlustration} alt='FlatIlustration' />
            </div>
            <LoginForm />
          </div>
        </div>
        <div className='registration__signin-section'>
          <p>Нет аккаунта?</p>
          <ButtonWithIcon
            btnText={'Зарегистрироваться'}
            widtIcon={true}
            icon={<UserIcon />}
            btnIconClassName='register-header-btn-icon'
            className='registration-header-btn-icon'
            btnOnClick={handleClick}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default LoginPage;
