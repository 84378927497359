import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/register/authSlice';
import loginReducer from '../features/login/loginSlice';
import addOfficeSlice from '../features/add-office/add-office-slice';
import approvRegistrationCodeSlice from '../features/approve-registration-code/approve-registration-code-slice';
import recoveryPasswordSlice from '../features/recovery-password/recoveryPasswordSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    login: loginReducer,
    addOffice: addOfficeSlice,
    approveRegister: approvRegistrationCodeSlice,
    recoveryPassword: recoveryPasswordSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
