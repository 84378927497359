import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const approvRegistrationCode = createAsyncThunk(
    'auth/verify-code',
    async (userData, { rejectWithValue }) => {
      try {
        const response = await $api.post('/api/user/verify-code', userData);
        console.log('response', response)
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const ApprovRegistrationCodeSlice = createSlice({
    name: 'auth',
    initialState: {
      user: null,
      isLoading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(approvRegistrationCode.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(approvRegistrationCode.fulfilled, (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.user = action.payload;
        })
        .addCase(approvRegistrationCode.rejected, (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default ApprovRegistrationCodeSlice.reducer;