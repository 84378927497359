import React from 'react'

const InputCheckbox = ({className, id, onChange}: any) => {
  return (
        <input
            type='checkbox'
            className={className}
            id={id}
            onChange={onChange}
        />
  )
}

export default InputCheckbox