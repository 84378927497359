import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import InputLabel from '../../input/inputLabel/Input-label';
import ButtonComponent from '../../ui/button/button';
import EyeIcon from '../../../assets/icons/eye-icon';
import { updatePassword } from '../../../features/update-password/updatePasswordSlice';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import UserIcon from '../../../assets/icons/user-icon';
import MainLayout from '../../layouts/mainLayout';
import ErrorComponent from '../../ui/error/error-component';
import Spinner from '../../ui/spinner/spinner';
import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';

const RecoveryPasswordForm = () => {
  const navigate = useNavigate();
  const { recoverPasswordLink, encodedEmail, timestamp }: any = useParams();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [noExistEmailError, setNoExistEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const isLoading = useSelector((state: any) => state.auth?.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
      if (recoverPasswordLink && timestamp) {
          const currentTime = Date.now();
          const linkExpiryTime = 30 * 60 * 1000;

          if (currentTime - timestamp > linkExpiryTime) {
              navigate('/error');
          } else {
              try {
                  const decodedEmail = Base64.decode(encodedEmail);
                  setEmail(decodedEmail);
                  console.log('Decoded email: ', decodedEmail);
              } catch (error) {
                  console.error('Invalid token');
                  navigate('/error');
              }
          }
      } else {
          navigate('/error');
      }
  }, [recoverPasswordLink, timestamp, navigate]);

  const handleSubmit = async (event: any) => {
      event.preventDefault();
      const userData = { email, password };

      try {
          if (password !== repeatPassword) {
              setPasswordError(true);
          } else {
              setPasswordError(false);
          }

          if (email && password && repeatPassword && password === repeatPassword) {
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
                  setEmailError(true);
              } else {
                  setEmailError(false);
              }

              let result;
              if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
                // @ts-ignore
                  result = await dispatch(updatePassword(userData));
              }

              let resultPayload;
              if (typeof result?.payload == 'string') {
                  resultPayload = result?.payload;
              }

              const regex = /<pre>(.*?)<\/pre>/;
              const matches = resultPayload?.match(regex);

              if (matches && matches[1]) {
                  const errorMessage = matches[1].split('<br>')[0];
                  if (errorMessage.includes('Пользователь не существует')) {
                      setNoExistEmailError(true);
                  } else {
                      setNoExistEmailError(false);
                  }
              } else {
                  setNoExistEmailError(false);
              }

              if (!result?.error) {
                  navigate('/recovery-password-done');
              }
          }
      } catch (error) {}
  };

  const handleClick = () => {
      navigate('/');
  };

  return (
      <MainLayout>
          <div className='recovery-container'>
              {isLoading && (
                  <div className={'register-container__absolute-layer'}>
                      <Spinner />
                  </div>
              )}
              <div className='recovery-main recovery-form'>
                  <p className='recovery-title'>Восстановление пароля</p>
                  <form onSubmit={handleSubmit} className='login-form-main'>
                      {emailError && (
                          <ErrorComponent error='email должен быть действительным' errorClassName='recovery-form-error' />
                      )}
                      {noExistEmailError && (
                          <ErrorComponent error='Пользователь не существует' errorClassName='recovery-form-error' />
                      )}
                      <InputLabel
                          className='register-input'
                          labelClass='register-label'
                          label='Новый пароль'
                          type='password'
                          setValue={setPassword}
                          value={password}
                          icon={<EyeIcon />}
                          iconClass='eye-class'
                      />
                      <InputLabel
                          className='register-input'
                          labelClass='register-label'
                          label='Подтвердить новый пароль'
                          type='password'
                          setValue={setRepeatPassword}
                          value={repeatPassword}
                          icon={<EyeIcon />}
                          iconClass='eye-class'
                      />
                      {passwordError && (
                          <ErrorComponent error='Пароли не совпадают' errorClassName='recovery-form-error' />
                      )}
                      <ButtonComponent btnText='Готово' className='recovery-form-btn' />
                  </form>
              </div>
          </div>
          <div className='registration__signin-section'>
              <p>Нет аккаунта?</p>
              <ButtonWithIcon
                  btnText={'Зарегистрироваться'}
                  widtIcon={true}
                  icon={<UserIcon />}
                  btnIconClassName='register-header-btn-icon'
                  className='registration-header-btn-icon'
                  btnOnClick={handleClick}
              />
          </div>
      </MainLayout>
  );
};

export default RecoveryPasswordForm;
