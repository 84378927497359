import React from 'react';
import './modal.scss';
import ButtonComponent from '../../ui/button/button';
import { useNavigate } from 'react-router-dom';

const ModalComponent = ({
  className,
  modalBtnClassName,
  modalBtnHeaderClassName,
}: any) => {
  const navigate = useNavigate();

  const handleNavigateOffice = () => {
    navigate('/advertising-systems-office');
  };

  return (
    <div className='modal-container'>
      <div className='header-absolute-background-color'></div>
      <div className={className}>
        <p className={modalBtnHeaderClassName}>
          Спасибо, данные отправлены <br /> на модерацию.
        </p>
        <p>С вами свяжется менеджер</p>
        <ButtonComponent
          className={modalBtnClassName}
          btnText='К кабинетам'
          btnOnClick={handleNavigateOffice}
        />
      </div>
    </div>
  );
};

export default ModalComponent;
