import React, { useEffect, useRef, useState } from 'react';
import ButtonComponent from '../button/button';
import UserIcon from '../../../assets/images/user_account.png';
import UserHeader from '../../../assets/images/user-header.png';
import HeaderBell from '../../../assets/images/header-bell.png';
import ArrowDown from '../../../assets/images/arrow-down.png';
import FluentArrow from '../../../assets/images/fluent_arrow.png';
import ArrowUp from '../../../assets/images/arrow-up.png';
import './header-component.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loginSlice } from '../../../features/login/loginSlice';
import { Link, useNavigate } from 'react-router-dom';
import MainLogo from '../../../assets/icons/main-logo';

const HeaderComponent = ({
  className,
  btnIconClassName,
  rightSideClassname,
  rightSideText,
  leftsideText,
  headerBtnClick,
  headerBtnText,
  leftButton,
  rightSideMenu,
  btnClassName,
  isLogin,
}: any) => {
  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const user = useSelector((state: any) => state.login.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // @ts-ignore
    dispatch(loginSlice?.actions?.logoutUser());
    navigate('/');
  };

  const toggleModal = () => {
    setShowHeaderModal(!showHeaderModal);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowHeaderModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {isLogin && <div className='header-absolute-background-color'></div>}
      <div className={className}>
        {!leftButton && (
          <Link
            to={!!user ? '/advertising-systems-office' : '/'}
            className={leftsideText}
          >
            <MainLogo />
          </Link>
        )}
        {leftButton && (
          <div className='left-side-header'>
            <Link
              to={!!user ? '/advertising-systems-office' : '/'}
              className={leftsideText}
            >
              <MainLogo />
            </Link>
            <ButtonComponent
              btnText='Пополнить'
              widtIcon={false}
              btnIconClassName={btnIconClassName}
              btnOnClick={headerBtnClick}
              className={btnClassName}
              disabled
            />
          </div>
        )}
        {rightSideClassname && (
          <div className={rightSideClassname}>
            {rightSideMenu && user ? (
              <>
                <div className='header-right-side-user'>
                  <img src={HeaderBell} alt='HeaderBell' />
                  <div className='header-right-side-email'>
                    <img
                      src={UserHeader}
                      onMouseUp={toggleModal}
                      alt='UserHeader'
                    />
                    <p>{user?.email}</p>
                  </div>
                  <div
                    className='header-right-side-img'
                    onMouseUp={toggleModal}
                  >
                    {showHeaderModal ? (
                      <div>
                        <img src={ArrowUp} alt='Arrow Up' />
                      </div>
                    ) : (
                      <div>
                        <img src={ArrowDown} alt='Arrow Down' />
                      </div>
                    )}
                  </div>
                </div>
                {showHeaderModal && (
                  <div className='header-right-side-modal' ref={modalRef}>
                    <div className='header-right-side-modal-box'>
                      <img src={FluentArrow} alt='Fluent Arrow' />
                      <div onClick={handleLogout}>Выйти</div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className='header-signin-section'>
                <p>{rightSideText}</p>
                <ButtonComponent
                  btnText={headerBtnText}
                  widtIcon={true}
                  icon={UserIcon}
                  btnIconClassName={btnIconClassName}
                  btnOnClick={headerBtnClick}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderComponent;
