import React from 'react'

const ArrowDown = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 7L8.75 10.75ZM8.75 10.75L12.5 7Z" fill="#D9D9D9"/>
      <path d="M5 7L8.75 10.75L12.5 7" stroke="#8F97A9" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowDown