import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { IInputLabel } from './input-label-types';
import ClosedEyeIcon from '../../../assets/icons/closed-eye-icon';
import ReactCountryFlag from 'react-country-flag';
import './input-label.scss';
import DropDownIcon from '../../../assets/icons/dropDownIcon';
import { countries } from '../../../utils/countries';

function removeBackslashes(str: string) {
  return str.replace(/\\/g, '');
}

interface selectedCountryProp {
  name: string;
  code: string;
  mask: string;
}

const InputLabel = ({
  value,
  labelContainer,
  required,
  placeholder,
  type,
  name,
  label,
  setValue,
  disabled,
  className,
  step,
  icon,
  iconClass,
  labelClass,
  requireLabel = true,
  setFormError,
  formError,
  rightText,
  ...rest
}: IInputLabel) => {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<selectedCountryProp>(
    countries[0]
  );
  const [phoneValue, setPhoneValue] = useState(value || '');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null); // Новый ref для контейнера

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCountryChange = (countryCode: string) => {
    const selectedCountry = countries.find(
      (country) => country.code === countryCode
    );
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
    }
    setShowDropdown(false);
  };

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneValue(value);
    setValue(value);
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  // Сохраняем первоначальное состояние showDropdown
  const [initialShowDropdown, setInitialShowDropdown] = useState(false);

  useEffect(() => {
    // Устанавливаем начальное состояние showDropdown
    setInitialShowDropdown(showDropdown);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        showDropdown && // Проверяем, открыт ли выпадающий список
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  if (required) {
    required = 'required';
  }

  if (!value) {
    value = '';
  }
  if (type === 'numberWallet') {
    value = value
      .toString()
      .replace(/\s/g, '')
      .replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  return (
    <div className='relative' ref={containerRef}>
      <div className='label-container'>
        {label && <label className={labelClass}>{label}</label>}
        {requireLabel && <div className='label-required'>*</div>}
      </div>
      {type === 'tel' ? (
        <div className='phone-box'>
          <div
            className='phone-img phone-select' //register-select
            onMouseUp={toggleDropdown}
          >
            <ReactCountryFlag
              className='emojiFlag'
              countryCode={selectedCountry.code}
              svg
            />
            <DropDownIcon className={'dropdown-style'} />
          </div>

          {showDropdown && (
            <div className='phone-dropdown' ref={dropdownRef}>
              {countries.map((country) => (
                <div
                  key={country.code}
                  className='phone-dropdown-item'
                  onClick={() => handleCountryChange(country.code)}
                >
                  <ReactCountryFlag
                    className='emojiFlag'
                    countryCode={country.code}
                    svg
                  />
                  {country.code}
                </div>
              ))}
            </div>
          )}
          <InputMask
            {...rest}
            mask={selectedCountry.mask}
            className={`${className} ${
              formError && required && !phoneValue.trim() ? 'empty' : ''
            }`}
            onChange={onPhoneChange}
            value={phoneValue}
            disabled={disabled}
            placeholder={removeBackslashes(selectedCountry.mask)}
            required={required}
            onInvalid={(e: any) => {
              e.preventDefault();
              setFormError(true);
            }}
          />
        </div>
      ) : (
        <input
          {...rest}
          className={`${className} ${
            formError && required && !value.trim() ? 'empty' : ''
          }`}
          onChange={(e) => {
            onChange(e);
          }}
          value={value}
          type={showPassword ? 'text' : type}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          onInvalid={(e) => {
            e.preventDefault();
            setFormError(true);
          }}
          step={step}
        />
      )}
      {rightText && <div className='right-text'>{rightText}</div>}
      <div className={iconClass} onClick={toggleShowPassword}>
        {showPassword ? <ClosedEyeIcon /> : icon}
      </div>
    </div>
  );
};

export default InputLabel;
